

































import NotifyUserActionModel from "./NotifyUserActionModel";

import { Component, Mixins, Prop } from 'vue-property-decorator';

import UsersInfoMixin from "piramis-base-components/src/shared/logic/getUsersInfo/UsersInfoMixin";

@Component({
})
export default class NotifyUserActionView extends Mixins(UsersInfoMixin) {
  @Prop() model!: NotifyUserActionModel

  @Prop() disabled!: boolean

  format(tag: number) {
    return this.formatter(this.model.usersInfoService.usersInfos, tag.toString())
  }

  created() {
    this.model.usersInfoService.getUsersInfos(this.model.users)
  }
}
